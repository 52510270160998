<template>
  <div :class="{ mobile: isMobile }">
    <div class="header-wrap">
      <div class="content-wrap">
        <div class="logo-wrap flex f-ac">
          <img src="@/assets/imgs/logo.png" alt="卡车侠 truck man" />
          <div class="kcx-logo">
            <div>卡车侠</div>TRUCK MAN
          </div>
        </div>
      </div>
    </div>

    <div class="banner-wrap">
      <div class="content-wrap">
        <div class="banner-title">
          专为商用车设计
        </div>
        <p>卡车侠TRUCK MAN® 平台致力于帮助车队以及卡车司机与<br />
        全国各地的供应商、商家门店建立直接关系，并提供优质的加油、<br />
        保养、卡车维修和故障事故等车辆服务，为司机节省大量时间和成本。</p>
      </div>
    </div>

    <div class="content-wrap tc">
      <div class="bz-title">更好地管理故障</div>
      <div class="bz-desc">您知道吗?数千个卡车车队以及卡车司机使用卡车侠TRUCK MAN® 来更好地管理卡车故障、维修和设备维护等车辆服务。</div>
      <div class="f-bet flex-col">
        <div class="bz1">
          <div>免费账号</div>
          <div class="bz-con">对于车队用户来说，卡车侠TRUCK MAN®是免费的，我们的免费帐户可以让您完全访问直观的功能，帮助您更好地跟踪每个供应商的体验，保存首选位置，查看供应商服务、评级等等。</div>
        </div>
        <div class="bz2">
          <div class="bz-tit">每日更新</div>
          <div class="bz-con">我们的团队每天都会通过电话验证和更新卡车侠TRUCK MAN®供应商位置。我们每年至少两次验证数据库中的每个供应商列表，以便为您和您的团队提供最新的信息。</div>
        </div>
        <div class="bz3">
          <div class="bz-tit">任何服务</div>
          <div class="bz-con">值得信赖的来源，自2022年以来，卡车侠TRUCK MAN®一直是业界值得信赖且领先的服务资源。业内一些最大的车队、故障呼叫中心和成千上万的卡车司机每天都会使用我们的搜索功能。</div>
        </div>
        <div class="bz4">
          <div class="bz-tit">私人地点</div>
          <div class="bz-con">卡车侠TRUCK MAN®管理着超过 30,000 个地点，但我们知道我们无法列出您的车队使用的每个地点，因此 我们为您创建了一项功能，可在几秒钟内将任何缺失的地点添加到搜索中。没有中间人!</div>
        </div>
      </div>
    </div>

    <div class="banner-wrap fg">
      <div class="content-wrap">
        <div class="banner-user">
          卡车侠司机用户
        </div>
        <div class="banner-title">
          卡车侠 APP
        </div>
        <p>卡车侠APP是业界领先的卡车服务电商平台，<br />
          为卡车司机提供全国超过30,000个服务网点。</p>
      </div>
    </div>

    <div class="banner-wrap fangan tc">
      我们解决的<span>问题</span>
    </div>

    <div class="laike-wrap">
      <div class="content-wrap flex">
        <div class="laike-content">
          <div>
            <span class="lk-title">平台曝光多：</span>
            商用车商家完成入驻流程后，在卡车侠平台会展示线上门店，用户打开卡车侠即可看到线上门店团购活动以及门店的品牌、位置、商品、评价等信息。
          </div>
          <div>
            <span class="lk-title">客流覆盖广：</span>
            商用车商家可以通过团购，代金券，买单，晚定等各种合作方式，吸引新客到门店消费，扩展更多客流。
          </div>
          <div>
            <span class="lk-title">服务体系好：</span>
            专业人员提供合作湾程全指导，制定针对性运营计划，薯助商用车商家经营更好，生意更好，同时提供专属的市核通道，最快当日申请，当日即可成功入驻。
          </div>
          <div>
            致力于为卡车司机提供优质的消费体验，帮助商家创造更多生意，带东美好生活。商家在线上开店后可合作团购，代金券，买单，预定等丰富的活动，帮助加油站，轮胎门店以及保养维修门店等商户沉淀口碑，获取用户，增加复购等，进而轻松管理商户。
          </div>
        </div>
        <img class="app" src="@/assets/imgs/lk.png" alt="卡车侠来客app">
      </div>
    </div>

    <div class="laike-wrap kcx">
      <div class="content-wrap flex">
        <img class="app" src="@/assets/imgs/kcx.png" alt="卡车侠来客app">
        <div class="laike-content">
          <div>
            卡车侠作为一个商用车后市场 O2O 电商平台，对用户有以下好处：
          </div>
          <div>
            <span class="lk-title">提供便捷的服务：</span>
            用户可以通过平台轻松找到附近的商用车服务提供商，如维修店、加油站、轮胎店等，并进行在线预约和支付。
          </div>
          <div>
            <span class="lk-title">比较价格和服务：</span>
            平台上有多个服务提供商，用户可以比较不同提供商的价格和服务质量，选择最适合自己的服务。
          </div>
          <div>
            <span class="lk-title">保障服务质量：</span>
            平台对服务提供商进行审核和评价，用户可以查看其他用户的评价和反馈，选择信誉良好的服务提供商。
          </div>
          <div>
            <span class="lk-title">节省时间和成本：</span>
            用户可以通过平台预约服务，避免了等待和排队的时间，同时也可以节省维修和保养的成本。
          </div>
          <div>
            <span class="lk-title">提供专业的建议：</span>
            平台上有专业的技师和顾问，用户可以咨询他们关于商用车的维修和保养问题，获得专业的建议和指导。
          </div>
          <div>
            总之，卡车侠作为一个商用车后市场 O2O 电商平台，为用户提供了便捷、高效、优质的服务，帮助用户节省时间和成本，提高商用车的运营效率和安全性
          </div>
        </div>
      </div>
    </div>

    <div class="content-wrap">
      <div class="huoban tc">卡车侠合作伙伴</div>
    </div>
    <div class="content-wrap huobao-wrap f-bet">
      <img v-for="item in 45" :key="item" :src="`/logo/logo_${item}.png`" @mouseover="imgAddClass" alt="卡车侠合作伙伴">
    </div>


    <div class="content-wrap">
      <div class="huoban tc">新闻资讯</div>
    </div>
    <div class="content-wrap f-bet flex-col">
      <a href="https://mp.weixin.qq.com/s/eOpt-Rbd7tLfOfDyQb4thw" target="_blank">
        <div class="news1">
          <img src="@/assets/imgs/news.webp" alt="卡车侠资讯">
          <div class="news-bg">
            <div class="news-title">
              商用车的后市场如何？
            </div>
            商用车作为生产资料其市场从本质上区别于乘用车市场。<template v-if="!isMobile"><br /></template>
            商用车后市场是涵盖整车、发动机、零部件、经销商<template v-if="!isMobile">、维修企业、保险</template> ...
          </div>
        </div>
      </a>
      <div>
        <a href="https://mp.weixin.qq.com/s/-kVsubJjuSv4UAUgxWNBQA" target="_blank">
          <div class="news2 news-bg new-mb">
            <div class="news-title">
              商用车市场形势
            </div>
            过去三十年，商用车市场具有明显的阶段性特征<br />
            背后是经济发展阶段的变化：“GDP增长率+经济结构的变化”；政策法规 ...
          </div>
        </a>
        <a href="https://mp.weixin.qq.com/s/BxzpO2ObQtyMOmNlWHEsoQ" target="_blank">
          <div class="news2 news-bg">
            <div class="news-title">
              大车队背景下的后市场变革
            </div>
            快递企业“成长的烦恼”<br />
            快递企业发展的主要矛盾：目前汽车后市场服务与快递车队不匹配，具体...
          </div>
        </a>
      </div>
    </div>
    <div class="content-wrap f-bet flex-col">
      <a href="https://mp.weixin.qq.com/s/Cq4oD7KjaJz9rDiXzPqK8w" target="_blank">
        <div class="news2 news-bg  new3">
          <div class="news-title">
            用产品思维来看待商用车后市场
          </div>
          纵观整个商用车市场，从整个车后生态产品来看除了ETC跟柴油，<br />
          其他车后产品的信息仍然处于不对称阶段，在业内常用的定义口径中 ...
        </div>
      </a>
      <a href="https://mp.weixin.qq.com/s/bY0hFJWNxlMcPkxbBxy1vw" target="_blank">
        <div class="news2 news-bg new4">
          <div class="news-title">
            中国商用车后市场趋势展望
          </div>
          受运费竞争、运输效率提升等驱动，对集约的后市场服务、物流使能的市场<br />
          增长潜力逐步释放，服务赋能市场蕴含巨大机遇 价值链机会潜力 ...
        </div>
      </a>
    </div>

    <div class="banner-wrap bottom">
      <div class="content-wrap">
        <div class="banner-title">
          卡车侠TRUCK MAN®
        </div>
        <p>不提供中介服务。我们免费为您提供全国商家服务网络，<br />
          以便您直接与商家协商价格和时间，从而选择速度最快、<br />最好的商家为您服务。</p>
      </div>
    </div>

    <div class="content-wrap footer-wrap f-bet flex-col">
      <div class="f-logo">
        <img v-if="!isMobile" src="@/assets/imgs/b-logo.png" alt="卡车侠">
        <div class="title">杭州卡车侠科技有限公司(400-999-8590)</div>
        <div class="add f-ac">
          <img src="@/assets/imgs/lo.png" alt="卡车侠">
          浙江省杭州市拱墅区候圣街99号财智顺丰创新中心4幢301室
        </div>
      </div>
      <div class="home-msg" v-if="!isMobile">
        <div class="home-title">首页</div>
        <div>核心优势</div>
        <div>解决方案</div>
        <div>合作伙伴</div>
        <div class="icp">
          <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">浙ICP备2020033295号</a>
        </div>
      </div>
      <div class="f-ac tc">
        <div class="app wx">
          <img src="@/assets/imgs/wx.png" alt="卡车侠微信公众号">
          卡车侠公众号
        </div>
        <div class="app">
          <img src="@/assets/imgs/kcxapp.jpg" alt="卡车侠APP">
          卡车侠司机端
        </div>
        <div class="app">
          <img src="@/assets/imgs/lkapp.jpg" alt="卡车侠来客">
          卡车侠商家端
        </div>
      </div>
      <div class="icp mobile" v-if="isMobile">
        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">浙ICP备2020033295号</a>
      </div>
    </div>

  </div>
</template>

<script>
import 'animate.css';
import { debounce, isMobileOrPC } from "@/utils";

export default {
  data() {
    return {
      isMobile: false
    }
  },
  created() {
    this.changeSize()

    const getDev = debounce(() => {
      this.changeSize()
    }, 100)
    window.addEventListener("resize", getDev);
  },
  methods: {
    imgAddClass(e) {
      e.target.classList.add('anim')
      setTimeout(() => {
        e.target.classList.remove('anim')
      }, 1500);
    },
    changeSize() {
      this.isMobile = isMobileOrPC()
    }
  }
}
</script>

<style lang="scss" scoped>
.icp {
  a {
    color: #999;
    font-size: 12px;
  }
  &.mobile {
    margin-bottom: 50px;
    ;
  }
}
.app {
  margin-left: 40px;
  margin-top: 6px;
  img {
    width: 100px;
    margin-bottom: 30px;
  }
  &.wx {
    margin-top: 0px;
    img {
      margin-bottom: 20px;
      width: 115px;
      margin-bottom: 20px;
    }
  }
}
.home-msg {
  font-size: 14px;
  line-height: 2;
  color: rgba(51, 51, 51, 0.6);
  .home-title {
    font-size: 16px;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
  }
}
.footer-wrap {
  padding: 80px 0;

  .title {
    margin: 40px 0 20px;
    font-size: 18px;
    font-weight: bold;
  }
  .add {
    font-size: 14px;
    img {
      margin-right: 6px;
    }
  }
}
a {
  display: inline-block;
  width: 100%;
}
.news1 {
  width: 580px;
  position: relative;
  img {
    width: 100%;
    border-radius: 8px;
  }
}
.news-bg {
  border-radius: 0 0 8px 8px;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 45%;
  color: #fff;
  background: linear-gradient(0.5deg, rgba(0, 0, 0, 1) 6.398%, rgba(152, 195, 250, 0) 100%);
  font-weight: 400;
  font-size: 16px;
  color: #A0A0A0;
  line-height: 1.5;
  padding: 20px;

  .news-title {
    font-weight: 600;
    font-size: 22px;
    color: #FFFFFF;
    margin-bottom: 15px;
  }

  &.news2 {
    position: initial;
    color: #999;
    background: #F8F8F8;
    border-radius: 8px;
    width: 600px;

    .news-title {
      font-size: 22px;
      color: #333;
    }
    &.new3 {
      width: 580px;
      margin-top: 20px;
    }
  }
}
.new-mb {
  margin-bottom: 20px;
}
.new4 {
  width: 600px;
  margin-top: 20px;
}
.huobao-wrap {
  flex-wrap: wrap;
  max-width: 100%;
  padding: 50px 40px 20px;
  background: #F8F8F8;
  img {
    width: 9%;
    margin-right: 2%;
    margin-bottom: 30px;
    border-radius: 8px;
    background-color: #fff;
    &.anim {
      animation: flip;
      animation-duration: 1s;
    }

    &:nth-child(9n) {
      margin-right: 0;
    }
  }
}
.huoban {
  padding: 150px 0 80px;
  font-weight: 600;
  font-size: 60px;
  color: #000000;
}
.laike-wrap {
  min-height: 600px;
  background: url('../assets/imgs/shjhy.png') no-repeat center top;
  background-size: auto 200px;

  img.app {
    width: 360px;
    margin-top: 100px;
  }

  .laike-content {
    font-weight: 400;
    font-size: 18px;
    color: #999999;
    line-height: 2;
    padding-top: 300px;
    padding-left: 30px;

    & > div {
      margin-top: 20px;
      padding-right: 125px;
    }

    .lk-title {
      font-weight: 600;
      font-size: 24px;
      color: #000;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 12px;
        left: -20px;
        width: 8px;
        height: 8px;
        background-color: #000;
        border-radius: 50%;
      }
    }
  }
  &.kcx {
    margin-top: 100px;
    background: url('../assets/imgs/sjhy.png') no-repeat center top;
    background-size: auto 200px;
    .laike-content {
      padding-top: 200px;
      padding-left: 130px;
      & > div {
        margin-top: 10px;
        padding-right: 0;
      }
    }
  }
}
.bz1, .bz2, .bz3, .bz4 {
  width: 280px;
  height: 480px;
  background: url('../assets/imgs/1bg.png') no-repeat center top;
  background-size: cover;
  margin-bottom: 80px;
  border-radius: 20px;
  position: relative;
  padding-top: 200px;
  font-weight: 500;
  font-size: 28px;
  animation: fadeInUp;
  animation-duration: 3s;
  .bz-con {
    font-weight: 400;
    font-size: 16px;
    color: #678B98;
    line-height: 28px;
    padding: 20px 30px 0;
    text-align: left;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
    height: 200px;
    background: url('../assets/imgs/1ico.png') no-repeat center;
    background-size: cover;
  }
}
.bz2 {
  background: url('../assets/imgs/2bg.png') no-repeat center top;
  background-size: cover;
  animation: fadeInUp;
  animation-duration: 3.5s;
  &::before {
    width: 160px;
    height: 160px;
    top: 20px;
    background: url('../assets/imgs/2ico.png') no-repeat center;
    background-size: cover;
  }
}
.bz3 {
  background: url('../assets/imgs/3bg.png') no-repeat center top;
  background-size: cover;
  animation: fadeInUp;
  animation-duration: 4s;
  &::before {
    width: 160px;
    height: 160px;
    top: 20px;
    background: url('../assets/imgs/3ico.png') no-repeat center;
    background-size: cover;
  }
}
.bz4 {
  background: url('../assets/imgs/4bg.png') no-repeat center top;
  background-size: cover;
  animation: fadeInUp;
  animation-duration: 4.5s;
  &::before {
    width: 160px;
    height: 160px;
    top: 20px;
    background: url('../assets/imgs/4ico.png') no-repeat center;
    background-size: cover;
  }
}
.bz-title {
  margin-top: 100px;
  font-weight: 600;
  font-size: 60px;
  margin-bottom: 30px;
  animation: fadeInDown;
  animation-duration: 2s;
}
.bz-desc {
  font-weight: 400;
  font-size: 18px;
  color: #919191;
  line-height: 28px;
  animation: fadeInUp;
  animation-duration: 2s;
  margin-bottom: 50px;
}
.banner-wrap {
  height: 600px;
  color: #fff;
  background: url('../assets/imgs/banner.png') no-repeat center center;
  background-size: auto 700px;
  font-weight: 400;
  font-size: 22px;
  color: #FFFFFF;
  line-height: 1.6;
  padding-top: 200px;

  .banner-user {
    margin-bottom: 20px;
  }

  .banner-title {
    font-weight: 600;
    font-size: 60px;
    margin-bottom: 50px;
    line-height: 1.2;

    animation: zoomInRight;
    animation-duration: 1.5s;
  }
  p {
    animation: fadeInUp;
    animation-duration: 1s;
  }

  &.fg {
    background: url('../assets/imgs/fg.png') no-repeat center center;
    background-size: auto 680px;
  }
  &.fangan {
    margin-top: 100px;
    height: 1200px;
    background: url('../assets/imgs/ht.png') no-repeat center 150px;
    background-size: auto 1000px;
    font-weight: 600;
    font-size: 60px;
    color: #000000;
    padding-top: 0;
    span {
      color: #e40e0b;
    }
  }

  &.bottom {
    margin-top: 150px;
    padding-top: 50px;
    height: 650px;
    background: url('../assets/imgs/bottom.png') no-repeat center center;
    background-size: auto 700px;

    .content-wrap {
      padding-left: 500px;
      .banner-title {
        margin-bottom: 20px;
      }
    }
  }
}
.logo-wrap {
  margin-left: 10px;
  height: 80px;
  .kcx-logo {
    font-size: 12px;
    font-weight: 600;
    div {
      font-size: 20px;
      margin-bottom: 4px;
    }
  }

  img {
    width: 77px;
    height: 32px;
    margin-right: 10px;
  }
}
.header-wrap {
  color: #fff;
  background: #CE323B;
}
.header-wrap, .banner-wrap {
  min-width: 1200px;
}

.mobile {
  .header-wrap, .banner-wrap {
    min-width: initial;
    width: 100%;
  }
  .banner-wrap {
    height: 70vw;
    color: #fff;
    background: url('../assets/imgs/banner.png') no-repeat center center;
    background-size: 200vw auto;
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 1.6;
    padding: 90px 10px 0;

    .banner-user {
      margin-bottom: 20px;
    }

    .banner-title {
      font-size: 22px;
      margin-bottom: 40px;
      line-height: 1.2;
    }

    &.fg {
      background: url('../assets/imgs/fg.png') no-repeat center center;
      background-size: auto 680px;
    }
    &.fangan {
      margin-top: 50px;
      height: 110vw;
      background: url('../assets/imgs/ht.png') no-repeat center 10vw;
      background-size: auto 100vw;
      font-size: 22px;
      color: #000000;
      padding-top: 0;
    }

    &.bottom {
      margin-top: 20px;
      padding-top: 60px;
      height: 50vw;
      background-position: center 0;
      background-size: 150vw auto;

      .content-wrap {
        padding-left: 20px;
        .banner-title {
          margin-bottom: 20px;
        }
      }
    }
  }

  .laike-wrap {
    min-height: 120vw;
    background-size: 120vw auto;

    img.app {
      width: 50vw;
      height: 90vw;
      margin-top: 20vw;
      margin-left: 10px;
    }

    .laike-content {
      font-size: 12px;
      padding-top: 15vw;
      padding-left: 20px;
      line-height: 1.5;

      & > div {
        margin-top: 10px;
        padding-right: 0;
      }

      .lk-title {
        font-weight: 600;
        font-size: 14px;
        &::before {
          display: none;
        }
      }
    }
    &.kcx {
      margin-top: 20px;
      background-size: 120vw auto;
      img.app {
        margin-top: 20vw;
        margin-left: 10px;
      }
      .laike-content {
        padding-top: 15vw;
        padding-left: 20px;
        & > div {
          margin-top: 5px;
          padding-right: 0;
        }
      }
    }
  }

  .bz-title {
    margin-top: 50px;
    font-size: 22px;
  }
  .bz-desc {
    padding: 0 10px;
    font-size: 13px;
  }
  .bz1, .bz2, .bz3, .bz4 {
    width: 90vw;
    height: auto;
    background: url('../assets/imgs/1bg.png') no-repeat center top;
    background-size: cover;
    margin-bottom: 10px;
    border-radius: 20px;
    padding-top: 28vw;
    padding-bottom: 20px;
    font-size: 22px;
    .bz-con {
      font-size: 14px;
    }

    &::before {
      width: 35vw;
      height: 35vw;
      top: -20px;
    }
  }
  .bz2, .bz3, .bz4 {
    &::before {
      width: 30vw;
      height: 30vw;
      top: -10px;
    }
  }
  .content-wrap {
    margin: 0 auto;
    min-width: initial;
    max-width: initial;
    width: 100%;

    .news1, .news2 {
      width: 90vw;
      margin-left: 5vw;
      margin-bottom: 10px;
    }
  }
  .news-bg {
    width: 100%;
    height: auto;
    min-height: 45%;
    font-size: 14px;
    bottom: 0;
    padding: 10px;

    .news-title {
      font-size: 16px;
      margin-bottom: 10px;
    }

    &.news2 {
      width: 90vw;
      margin-top: 0;
      margin-left: 5vw;
      border-radius: 8px;
      &.new3 {
        width: 90vw;
        margin-top: 0;
        margin-left: 5vw;
      }
    }
  }
  .flex-col {
    flex-direction: column;
  }
  .huoban {
    padding: 30px 0 20px;
    font-size: 22px;
    color: #000000;
  }
  .huobao-wrap {
    max-width: 100%;
    padding: 10px;
    img {
      width: 18%;
      margin-right: 2%;
      margin-bottom: 10px;
      &:nth-child(9n) {
        margin-right: 2%;
      }
      &:nth-child(5n) {
        margin-right: 0;
      }
    }
  }
  .footer-wrap {
    padding: 0;

    .title {
      margin-bottom: 10px;
      font-size: 14px;
      color: #666;
    }
    .add {
      font-size: 12px;
      color: #999;
      img {
        opacity: 0.7;
        margin-right: 6px;
      }
    }
    .app {
      margin-left: 10px;
      margin-top: 18px;
      padding-bottom: 30px;
      img {
        margin-bottom: 10px;
      }

      &.wx {
        margin-top: 10px;
        img {
          margin-bottom: 2px;
        }
      }
    }
  }
}
</style>