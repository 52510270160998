export function mobileDevice() {
  const ua = navigator.userAgent.toLowerCase();
  const ios = /ipad|iphone|ipod/.test(ua) && !window.MSStream; // ios终端
  const android = /android/i.test(ua); // android
  const harmonyos = /harmonyos/i.test(ua); // 鸿蒙
  return ios || android || harmonyos || !!window.ReactNativeWebView;
}

export function isMobileOrPC() {
  let isMobile = mobileDevice()
  window.isMOBILE = isMobile

  // 返回指定的媒体查询字符串解析后的结果
  const mediaQuery = window.matchMedia('(max-width: 600px)')
  if (!isMobile && mediaQuery.matches) {
    window.isMOBILE = true
    isMobile = true
  }
  return isMobile;
}


export const debounce = (func, wait, immediate) => {
  // 去抖
  let context
  let result
  let timeout

  function ResDebounced(...args) {
    // 这个函数里面的this就是要防抖函数要的this
    // args就是事件对象event
    context = this

    // 一直触发一直清除上一个打开的延时器
    if (timeout) {
      clearTimeout(timeout)
    }

    if (immediate) {
      // 第一次触发，timeout === undefined恰好可以利用timeout的值
      const callNow = !timeout
      timeout = setTimeout(() => {
        timeout = null
      }, wait)
      if (callNow) {
        result = func.apply(context, args)
      }
    } else {
      // 停止触发，只有最后一个延时器被保留
      timeout = setTimeout(() => {
        timeout = null
        // func绑定this和事件对象event，还差一个函数返回值
        result = func.apply(context, args)
      }, wait)
    }
    return result
  }
  ResDebounced.cancal = function () {
    clearTimeout(timeout)
    timeout = null
  }
  return ResDebounced
}

// 标签滚动到可视区域
export function scrollToView(ele, block = 'center') {
  ele && ele.scrollIntoView({
    behavior: 'smooth',
    block,
    inline: 'nearest'
  });
}

// 动态加载css
export function loadCss(url) {
  const link = document.createElement('link');
  link.href = url;
  link.rel = 'stylesheet';
  document.head.appendChild(link);
};
